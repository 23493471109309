import React from 'react'
import logo from "../../../assets/images/logos/logo.png"
import "./ThanksStore.scss"


export default function ThanksStore() {
  return (
    <div className="thankspage">
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src={logo}/>
          <h3 className="d-none d-md-flex">Muchas gracias por tu <br /> compra</h3>
          <h3 className="d-flex d-md-none">Muchas gracias <br /> por tu compra</h3>
          <a
            className="buybutton"
            direction="up"
            duration="0.5"
            bg="#ffc311"
            href="/corona-store"
          >
            Regresar al Home
          </a>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import styles from "./FooterStore.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

export function FooterStore() {
  return (
    <div className={styles.FooterStore}>
      <Container>
        <Row className={styles.row}>
          <Col xs={6} md={3} className={"text-center"}>
            <a href={"https://www.instagram.com/corona_argentina"}>Contacto</a>
          </Col>
          <Col xs={6} md={3} className={"text-center"}>
            <a href={"/TyCCervezaCorona.pdf"}>términos y condiciones</a>
          </Col>
          <Col xs={6} md={3} className={"text-center"}>
            <a href={"ppdp.pdf"}>política de privacidad</a>
          </Col>
          <Col xs={6} md={3} className={"text-center"}>
            <a
              href={
                "https://www.cerveceriaymalteriaquilmes.com/sustentabilidad-consumo-responsable-anterior/"
              }
            >
              consumo responsable
            </a>
          </Col>
        </Row>
        <Row className={styles.row2}>
          <Col className={"text-center"}>
            BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. NO
            COMPARTA EL CONTENIDO CON MENORES. @2020 Quilmes - Buenos Aires -
            Argentina. Todos los derechos reservados.
          </Col>
        </Row>
      </Container>
    </div>
  )
}

import React from "react"
import "../styles/FooterSunset.css"
import facebook from "../../assets/images/social/Facebook.svg"
import instagram from "../../assets/images/social/Instagram.svg"
import Col from "react-bootstrap/Col"

export default function FooterPages() {
  return (
    <footer className="container-fluid footerSunset">
      <div className="socialMenu">
        <a href="https://www.facebook.com/cervezacorona" target="_blank">
          <img className={"imgSocialFace"} src={facebook} />
        </a>
        <a href="https://www.instagram.com/corona_argentina" target="_blank">
          <img className={"imgSocialInsta"} src={instagram} />
        </a>
      </div>
      <div className="column justify-content-center">
        <div className="col">
          <p className="legalterms text-center">
            No se quede conectado si comparte el dispositivo con menores. <br />
            BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. NO
            COMPARTA EL CONTENIDO CON MENORES. ®️2021 Corona - Buenos Aires -
            Argentina. Todos los derechos reservados.
          </p>
        </div>
        <div className="col">
          <div className="row text-center pb-4 justify-content-center">
            <a
              className="termLink mt-1"
              href={"/TyCCervezaCorona.pdf"}
              target="_blank"
              download
            >
              <p>Términos de uso </p>
            </a>
            <span>|</span>
            <a
              className="termLink mt-1"
              href={"/ppdp.pdf"}
              target="_blank"
              download
            >
              <p>Políticas de privacidad</p>
            </a>
            <span>|</span>
            <a
              className="termLink mt-1"
              href={"https://www.instagram.com/corona_argentina"}
              target="_blank"
              download
            >
              <p>Contacto</p>
            </a>
            <span>|</span>
            <a
              className="termLink mt-1"
              href={
                "https://www.cerveceriaymalteriaquilmes.com/sustentabilidad-consumo-responsable-anterior/"
              }
              target="_blank"
              download
            >
              <p>consumo responsable</p>
            </a>
            <span>|</span>
            <a
              className="termLink mt-1"
              href={"https://www.cerveceriaymalteriaquilmes.com/"}
              target="_blank"
              download
            >
              <p>INFORMACIÓN OFICIAL DE LA COMPAÑÍA</p>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

import React from "react"
import SEO from "../../components/seo"
import { FooterStore } from "../../components/Store/FooterStore"
import FooterSmall from "../../components/FooterSunset"

import useWindowDimensions from "../../hooks/useWindowDimensions"
import ThanksStore from "../../components/Store/ThanksStore"

export default function MuchasGracias() {
  const { width } = useWindowDimensions()

  return (
    
    <div>
       <SEO title="Muchas Gracias | Corona Store" />
       <ThanksStore />
       {width <= 500 ? (
        null
      ) : (
        <FooterStore />
      )}
    </div>
  )
}
